
<template>
	<div id="setting" style="padding-left: 10px;">  
    <div>
      <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    </div>
    <div>
      <v-select style="width: 200px" placeholder="시리즈 수정" label="name"
        :options="seriesArray" @input="setSeries"/>
    </div>
    <div class="flex flex-wrap">
      <div style="margin: 20px 0 0; width: 250px; background-color: white;">
        <vs-radio v-model="bannerLinkType" vs-value="ext">외부</vs-radio>
        <vs-radio v-model="bannerLinkType" vs-value="search">검색</vs-radio>
        <vs-radio v-model="bannerLinkType" vs-value="searchTag">태그검색</vs-radio>
        <vs-radio v-model="bannerLinkType" vs-value="series">시리즈</vs-radio>
        <vs-radio v-model="bannerLinkType" vs-value="category2">카테고리</vs-radio>
        <vs-input class="inputx" v-if="bannerLinkType == 'ext'" placeholder="외부링크 입력" v-model="bannerLink" @change="setBannerLink('ext')" />
        <vs-input class="inputx" v-if="bannerLinkType == 'search'" placeholder="검색단어 입력" v-model="searchText" @change="setBannerLink('search')" />
        <vs-input class="inputx" v-if="bannerLinkType == 'searchTag'" placeholder="검색단어 입력" v-model="searchText" @change="setBannerLink('searchTag')" />
        <v-select class="inputx" v-if="bannerLinkType == 'series'" placeholder="시리즈 선택" @input="setBannerLink('series')" 
                  v-model="bannerSeries" label="name" :options="$store.state.series" :clearable="false" />
        <v-select class="inputx" v-if="bannerLinkType == 'category2'" placeholder="카테고리 선택" @input="setBannerLink('category2')" 
                  v-model="bannerCategory2" label="name" :options="$store.state.categories2" :clearable="false" />
        <vs-textarea v-model="bannerTag" />
        <vs-upload ref="bannerUpload" automatic limit="1" accept="image/*" @on-success="successUpload" @on-error="errorUpload" @on-delete="deleteFile"
          action="/api/photo/bannerUpload" />
      </div>
      <div style="margin: 20px 0 0; width: 250px; background-color: white;">
        <vs-input class="inputx" placeholder="모바일 태그 제목" v-model="bannerTitle2" />
        *색상 입력 시 국민들은 지금 형식으로 입력된 색상이 입혀짐. 색상은 #FFFFFF 형식으로.
        <vs-input class="inputx" placeholder="색상 입력" v-model="bannerData2" />
        <vs-radio v-model="bannerLinkType2" vs-value="search">검색</vs-radio>
        <vs-radio v-model="bannerLinkType2" vs-value="searchTag">태그검색</vs-radio>
        <vs-input class="inputx" v-if="bannerLinkType2 == 'search'" placeholder="검색단어 입력" v-model="searchText2" @change="setBannerLink2('search')" />
        <vs-input class="inputx" v-if="bannerLinkType2 == 'searchTag'" placeholder="검색단어 입력" v-model="searchText2" @change="setBannerLink2('searchTag')" />
        <vs-textarea v-model="bannerTag2" />
      </div>
      <div style="margin: 20px 0 0; width: 250px; padding: 0 10px;">
        <div class="flex flex-wrap">
          <p style="line-height: 35px; width: 100px;">확진: </p><vs-input-number v-model="coronaData.diagnosed"/>
          <p style="line-height: 35px; width: 100px;">격리해제: </p><vs-input-number v-model="coronaData.exposed"/>
          <p style="line-height: 35px; width: 100px;">사망: </p><vs-input-number v-model="coronaData.dead"/>
          <p style="line-height: 35px; width: 100px;">검사진행: </p><vs-input-number v-model="coronaData.diagnosis"/>
          <p style="line-height: 35px; width: 70px;">기준시간: </p><datepicker style="width: 160px;" type="datetime" v-model="coronaData.date" lang="kr" format="YYYY-MM-DD HH:mm" :time-picker-options="{start: '00:00', step:'01:00' , end: '23:00', format: 'HH:mm' }" />
        </div>
        <vs-button class="mr-3 mb-2" style="margin: 20px 0 0" @click="sendCorona(true)">코로나 데이터 수정</vs-button>
        <vs-button class="mr-3 mb-2" style="" color="warning" @click="sendCorona(false)">코로나 표시정지</vs-button>
      </div>
      <!-- <div style="margin: 20px 0 0; width: 350px; padding: 0 10px;">
        <div class="flex flex-wrap">
          <p style="line-height: 35px; width: 100px;">상태: </p>
          <vs-radio v-model="olympicData.o_state" vs-value="0">비표시</vs-radio>
          <vs-radio v-model="olympicData.o_state" vs-value="1">표시</vs-radio>
        </div>
        <div class="flex flex-wrap">
          <p style="line-height: 35px; width: 100px;">순위: </p><vs-input-number v-model="olympicData.ranking"/>
        </div>
        <div class="flex flex-wrap">
          <p style="line-height: 35px; width: 100px;">금메달: </p><vs-input-number v-model="olympicData.gold"/>
        </div>
        <div class="flex flex-wrap">
          <p style="line-height: 35px; width: 100px;">은메달: </p><vs-input-number v-model="olympicData.silver"/>
        </div>
        <div class="flex flex-wrap">
          <p style="line-height: 35px; width: 100px;">동메달: </p><vs-input-number v-model="olympicData.bronze"/>
        </div>
        <div class="flex flex-wrap">
          <p style="line-height: 35px; width: 60px;">일정: </p><datepicker style="width: 160px;" type="date" v-model="olympicDate" @change="olympicSelectDate" lang="kr" format="YYYY-MM-DD" />
        <vs-textarea v-model="olympicScheduleText" />
        </div>
        <vs-button class="mr-3 mb-2" style="margin: 20px 0 0" @click="sendOlympic">올림픽 데이터 수정</vs-button>
      </div> -->
      <!-- <div style="margin: 20px 0 0; width: 550px; padding: 0 10px;">
        <div class="flex flex-wrap">
          <p style="line-height: 35px; width: 100px;">상태: </p>
          <vs-radio v-model="election2022Data.state" vs-value="0">비표시</vs-radio>
          <vs-radio v-model="election2022Data.state" vs-value="1">표시</vs-radio>
        </div>
        <div class="flex flex-wrap">
          <p style="line-height: 35px; width: 100px;">여론조사 결과: </p>
          <vs-textarea v-model="election2022Text" />
        </div>
        <div class="flex flex-wrap">
          <div style="width50%">
            <p style="line-height: 35px; width: 100%;">여론조사 기사/이미지1: </p>
            <div class="flex">
              <p style="line-height: 35px; width: 100px;">기사번호1: </p>
              <vs-input-number v-model="election2022Data.pollNews[0].id"/>
            </div>
            <vs-upload ref="electionUpload1" automatic limit="1" accept="image/*" 
              @on-success="setPollsImage1" 
              @on-error="errorPollsUpload1" 
              @on-delete="deleteUpload('polls1')"
              action="/api/photo/bannerUpload" orientation="h" />
          </div>
          <div style="width50%">
            <p style="line-height: 35px; width: 100%;">여론조사 기사/이미지2: </p>
            <div class="flex">
              <p style="line-height: 35px; width: 100px;">기사번호2: </p>
              <vs-input-number v-model="election2022Data.pollNews[1].id"/>
            </div>
            <vs-upload ref="electionUpload2" automatic limit="1" accept="image/*" 
              @on-success="setPollsImage2" 
              @on-error="errorPollsUpload2" 
              @on-delete="deleteUpload('polls2')"
              action="/api/photo/bannerUpload" orientation="h" />
          </div>
        </div>
        <vs-button class="mr-3 mb-2" style="margin: 20px 0 0" @click="sendElection2022">2022대선 데이터 수정</vs-button>
      </div> -->
      <!-- <div class="flex flex-wrap">
        <div style="margin: 20px 0 0; width: 250px; padding: 0 10px;">
          <p>키워드 입력 (크기 순)</p>
          <vs-input size="small" class="inputx" placeholder="키워드1" v-model="electionData.keyword1"/>
          <vs-input size="small" class="inputx" placeholder="키워드2" v-model="electionData.keyword2"/>
          <vs-input size="small" class="inputx" placeholder="키워드3" v-model="electionData.keyword3"/>
          <vs-input size="small" class="inputx" placeholder="키워드4" v-model="electionData.keyword4"/>
          <vs-input size="small" class="inputx" placeholder="키워드5" v-model="electionData.keyword5"/>
          <vs-input size="small" class="inputx" placeholder="키워드6" v-model="electionData.keyword6"/>
          <vs-input size="small" class="inputx" placeholder="키워드7" v-model="electionData.keyword7"/>
          <vs-input size="small" class="inputx" placeholder="키워드8" v-model="electionData.keyword8"/>
          <vs-button class="mr-3 mb-2" style="margin: 10px 0 0" @click="sendElection(true)">총선 데이터 수정</vs-button>
          <vs-button class="mr-3 mb-2" style="" color="warning" @click="sendElection(false)">총선 표시정지</vs-button>
        </div>
        <div style="margin: 20px 0 0; width: 250px;">
          <p>SNS 입력</p>
          <v-select style="width: 200px" placeholder="0" v-model="sns.selected"
            :options="['추가',0,1,2,3,4]" :clearable="false" @input="selectSnsData"/>
          <vs-input size="small" class="inputx" placeholder="링크" v-model="sns.url"/>
          <vs-upload ref="snsUpload" automatic limit="1" accept="image/*" @on-success="setSnsImage" @on-error="errorSnsUpload" @on-delete="deleteUpload('sns')"
            action="/api/photo/bannerUpload" orientation="h" />
          <vs-button class="mr-3 mb-2" style="margin: 10px 0 0" @click="sendElectionSns(sns)">총선 SNS 데이터 {{sns.button}}</vs-button>
        </div>
        <div style="margin: 20px 0 0; width: 250px;">
          <p>한눈에 보는 총선 입력</p>
          <v-select style="width: 200px" placeholder="0" v-model="electionGraphic.selected"
            :options="['추가',0,1,2,3]" :clearable="false" @input="selectGraphicData"/>
          <vs-input size="small" class="inputx" placeholder="링크" v-model="electionGraphic.url"/>
          <vs-input size="small" class="inputx" placeholder="제목" v-model="electionGraphic.title"/>
          <vs-upload ref="graphicUpload" automatic limit="1" accept="image/*" @on-success="setGraphicImage" @on-error="errorGraphicUpload" @on-delete="deleteUpload('graphic')"
            action="/api/photo/bannerUpload" orientation="h" />
          <vs-button class="mr-3 mb-2" style="margin: 10px 0 0" @click="sendElectionGraphic(electionGraphic)">한눈에 보는 총선 {{electionGraphic.button}}</vs-button>
        </div>
        <div style="margin: 20px 0 0; width: 250px;">
          <p>격전지 입력</p>
          <v-select style="width: 200px" v-model="electionTug.area"
            :options="tugOption" :clearable="false" @input="selectTugArea"/>
          <v-select style="width: 200px" placeholder="0" v-model="electionTug.selected"
            :options="tugAreaOption" :clearable="false" @input="selectAreaData"/>
          <v-select ref="tagSelect" placeholder="태그 입력" class="vs-con-input-label vs-tag" @option:created="tagFocusFlg = true;" @search:blur="setSelected" taggable multiple v-model="electionTug.tags"/>
          <vs-upload ref="tugUpload" automatic limit="1" accept="image/*" @on-success="setTugImage" @on-error="errorTugUpload" @on-delete="deleteUpload('tug')"
            action="/api/photo/bannerUpload" orientation="h" />
          <vs-button class="mr-3 mb-2" style="margin: 10px 0 0" @click="sendElectionTug(electionTug)">격전지 {{electionTug.button}}</vs-button>
        </div>
        <div style="margin: 20px 0 0; width: 250px;">
          <p>지난선거결과 입력</p>
          <v-select style="width: 200px" v-model="electionPast.selected"
            :options="pastOption" :clearable="false" @input="selectPastData"/>
          <vs-upload ref="pastUpload" automatic limit="1" accept="image/*" @on-success="setPastImage" @on-error="errorPastUpload" @on-delete="deleteUpload('past')"
            action="/api/photo/bannerUpload" orientation="h" />
          <vs-button class="mr-3 mb-2" style="margin: 10px 0 0" @click="sendElectionPast(electionPast)">지난선거결과 {{electionPast.button}}</vs-button>
        </div>
      </div> -->
      <!-- <div style="margin: 20px 0 0; width: 350px; padding: 0 10px;">
        <div class="flex flex-wrap">
          <p style="line-height: 35px; width: 100px;">표시: </p>
          <vs-radio v-model="worldcupData.state" vs-value="false">비표시</vs-radio>
          <vs-radio v-model="worldcupData.state" vs-value="true">표시</vs-radio>
        </div>
        <div>
          <p style="line-height: 35px; width: 80px;">일정 결과: </p>
          <v-select style="width: 200px" placeholder="일정 선택" label="name"
            :options="worldcupData.schedule" v-model="worldcupSelected"/>
        </div>
        <div v-if="worldcupSelected" style="margin: 20px 0 0;">
          <div class="flex flex-wrap">
            <p>{{worldcupSelected.team[0]}}</p>
            <vs-input-number v-model="worldcupSelected.score[0]"/>
          </div>
          <div class="flex flex-wrap">
            <p>{{worldcupSelected.team[1]}}</p>
            <vs-input-number v-model="worldcupSelected.score[1]"/>
          </div>
          
          <md-checkbox v-model="worldcupSelected.state" >경기종료</md-checkbox>
        </div>
        <vs-button class="mr-3 mb-2" style="margin: 20px 0 0" @click="sendWorldcup">월드컵 데이터 수정</vs-button>
      </div> -->
    </div>
    
    <div class="flex flex-wrap">
      <!-- <div style="margin: 20px 0 0; width: 250px; padding: 0 10px;">
        <vs-list id="newsList" ref="newsList">
          <vs-list-header title="사이드바 순서(메인)" color="primary"></vs-list-header>
          <draggable :list="sidebarMain" group="newsList" class="p-2 cursor-move">
            <vs-list-item  v-for="(listItem, index) in sidebarMain" :key="index" :title="listItem.smp_title">
              </vs-list-item>
          </draggable>
        </vs-list>
        <vs-button class="mr-3 mb-2" style="" color="primary" @click="sendSidebar('sidebarMain', sidebarMain)">사이드바 순서 변경</vs-button>
      </div>
      <div style="margin: 20px 0 0; width: 250px; padding: 0 10px;">
        <vs-list id="newsList" ref="newsList">
          <vs-list-header title="사이드바 순서(기사)" color="primary"></vs-list-header>
          <draggable :list="sidebarArticle" group="newsList" class="p-2 cursor-move">
            <vs-list-item  v-for="(listItem, index) in sidebarArticle" :key="index" :title="listItem.smp_title">
              </vs-list-item>
          </draggable>
        </vs-list>
        <vs-button class="mr-3 mb-2" style="" color="primary" @click="sendSidebar('sidebarArticle', sidebarArticle)">사이드바 순서 변경</vs-button>
      </div> -->
      <div style="margin: 20px 0 0; width: 250px; padding: 0 10px;">
        <vs-list id="newsList" ref="newsList">
          <vs-list-header title="사이드바 순서(리뉴얼)" color="primary"></vs-list-header>
          <draggable :list="sidebarRenew" group="newsList" class="p-2 cursor-move">
            <vs-list-item  v-for="(listItem, index) in sidebarRenew" :key="index" :title="makeSidebarTitle(listItem)">
              </vs-list-item>
          </draggable>
        </vs-list>
        <vs-button class="mr-3 mb-2" style="" color="primary" @click="sendSidebar('sidebarRenew', sidebarRenew)">사이드바 순서 변경</vs-button>
      </div>
      <div style="margin: 20px 0 0; width: 250px; padding: 0 10px;">
        <vs-list id="newsList" ref="newsList">
          <vs-list-header title="네이버 프로모션 영역" color="primary"></vs-list-header>
        </vs-list>
        <div class="promotion">
          <div>1.</div>
          <div>제목</div>
          <vs-input class="inputx" placeholder="제목" v-model="promotion[0].title" />
          <div>URL</div>
          <vs-input class="inputx" placeholder="URL" v-model="promotion[0].url" />
          <div>QR코드</div>
          <md-checkbox v-model="promotion[0].qr" ></md-checkbox>
          <vs-list id="newsList" ref="newsList">
            <vs-list-header title="" color="primary"></vs-list-header>
          </vs-list>
          <div>2.</div>
          <div>제목</div>
          <vs-input class="inputx" placeholder="제목" v-model="promotion[1].title" />
          <div>URL</div>
          <vs-input class="inputx" placeholder="URL" v-model="promotion[1].url" />
          <div>QR코드</div>
          <md-checkbox v-model="promotion[1].qr" ></md-checkbox>
        </div>
        <vs-button class="mr-3 mb-2" style="margin: 20px 0 0" @click="sendPromotion()">적용</vs-button>
      </div>
    </div>
    <!-- Start: 시리즈 수정 팝업 -->
    <div id="div-with-loading" class="news-detail-form vs-con-loading__container" style ="width: 690px;">
      <vs-popup ref="popup-editSeries" classContent="popup-editSeries" title="시리즈 수정" :active.sync="seriesEditModalActive">
        <div class="vx-row" style="margin: 10px">
          <div class="vx-col sm:w-1/3 w-full">
          <span>시리즈 이름: </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" placeholder="이름 입력" v-model="seriesVar.name"/>
          </div>
        </div>
        <div class="vx-row" style="margin: 10px">
          <div class="vx-col sm:w-1/3 w-full">
          <span>시리즈 설명: </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" placeholder="설명 입력" v-model="seriesVar.description"/>
          </div>
        </div>
        <div class="vx-row" style="margin: 10px">
          <div class="vx-col sm:w-1/3 w-full">
          <span>해당 카테고리: </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">       
            <v-select style="width: 150px" placeholder="사용 여부" label="name" v-model="category1"
              :options="category1Array" :clearable="false"/>   
            <!-- <vs-select v-model="seriesVar.on_use">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in seriesUseOption"/>
            </vs-select> -->
          </div>
        </div>
        <div class="vx-row" style="margin: 10px">
          <div class="vx-col sm:w-1/3 w-full">
            <span>시리즈 사용여부: </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">       
            <v-select style="width: 150px" placeholder="사용 여부" label="text" v-model="on_use"
              :options="seriesUseOption" :clearable="false"/>   
            <!-- <vs-select v-model="seriesVar.on_use">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in seriesUseOption"/>
            </vs-select> -->
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>시리즈 아이콘</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <div class="seriesBanner">                    
              <form class="drag-drop" ref="fileform">
                <label class="drop-files"><p>여기를 클릭하거나 파일을 드래그해서 넣어주세요</p>
                <input type="file" @change="addFile"></label>
                <progress max="100" :value.prop="uploadPercentage"></progress>
              </form>
              <img :src="preview" style=" width: 200px;">
            </div>
          </div>
        </div>
        <div class="vx-row" style="margin: 10px">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="sendSeries()">{{buttonText}}</vs-button>
            <vs-button
              color="warning"
              type="border"
              class="mb-2"
              @click="seriesEditModalActive=false"
            >취소</vs-button>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- End: 시리즈 수정 팝업 -->
	</div>
</template>

<script>
import moment from 'moment';
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
export default {
  components: {
    draggable,
    'v-select': vSelect,
  },
  data(){
    return {
      sidebarMain:[],
      sidebarArticle:[],
      sidebarRenew:[],
      promotion:[
        {
          title: "",
          url: "",
          qr: 0,
        },
        {
          title: "",
          url: "",
          qr: 0,
        },
      ],
      // electionData: {
      //   keyword1: '',
      //   keyword2: '',
      //   keyword3: '',
      //   keyword4: '',
      //   keyword5: '',
      //   keyword6: '',
      //   keyword7: '',
      //   keyword8: '',
      // },
      // sns: {
      //   selected: '추가',
      //   url: '',
      //   image: '',
      //   button: '추가',
      // },
      // electionGraphic: {
      //   selected: '추가',
      //   url: '',
      //   title: '',
      //   image: '',
      //   button: '추가',
      // },
      // electionTug: {
      //   area: '서울',
      //   selected: '추가',
      //   tags: [],
      //   image: '',
      //   button: '추가',
      // },
      // electionPast: {
      //   selected: '추가',
      //   image: '',
      //   button: '추가',
      // },
      // tagFocusFlg: false,
      // tugOption: [
      //   '서울',
      //   '인천·경기',
      //   '충청',
      //   '강원',
      //   '대구·경북',
      //   '부산·경남',
      //   '광주·전남',
      // ],
      // tugAreaOption: ['추가'],
      // pastOption: ['추가'],
      // olympicData: {
      //   state: 0,
      //   ranking: 0,
      //   gold: 0,
      //   silver: 0,
      //   bronze: 0,
      //   title: '도쿄올림픽2020',
      //   link: '/olympic'
      // },
      // olympicDate: new Date(),
      // olympicScheduleText: "",
      // election2022Data:{
      //   state: 0,
      //   chartData: "",
      //   pollNews: [{}, {}],
      //   title: '2022대선',
      //   link: '/election2022'
      // },
      // election2022Text: "",
      // election2022Text2: "",
      worldcupData:{
        state: false,
        schedule: [],
      },
      worldcupSelected: null,
      coronaData: {
        diagnosed: 0,
        exposed: 0,
        dead: 0,
        diagnosis: 0,
        date: new Date(),
        use: 1,
      },
      seriesEditModalActive: false,
      seriesVar: {
        code: '',
        name: '',
        description: '',
        category1: '0',
        banner: '',
        on_use: null,
      },
      category1: {},
      on_use: null,
      seriesArray: [],
      category1Array: [],
      seriesUseOption: [
        { text: "사용", value: "1" },
        { text: "숨김", value: "0" }
      ],
      buttonText: "수정",
      bannerTag: "",
      searchText: "",
      bannerSeries: null,
      bannerCategory2: null,
      bannerLink: "",
      bannerLinkType: "ext",
      bannerData2: "",
      bannerTitle2: "",
      bannerTag2: "",
      searchText2: "",
      bannerSeries2: null,
      bannerCategory22: null,
      bannerLink2: "",
      bannerLinkType2: "searchTag",
      files: [],
      uploadPercentage: 0,
      preview: '',
    }
  },
  beforeRouteLeave (to, from, next) {
    this.seriesEditModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch:{
    bannerLinkType: function() {
      this.clearLink()
    },
    bannerLinkType2: function() {
      this.clearLink2()
    },
  },
  computed:{
    validName(){
      return (this.valMultipe.value1.length > 0 && this.valMultipe.value2.length > 0)
    }
  },
  methods:{
    deleteUpload(type){
      if(type == 'past'){
        this.$refs.pastUpload.itemRemove = [];
        this.$refs.pastUpload.filesx = [];
        this.$refs.pastUpload.srcs = [];
      }else if(type == 'tug'){
        this.$refs.tugUpload.itemRemove = [];
        this.$refs.tugUpload.filesx = [];
        this.$refs.tugUpload.srcs = [];
      }else if(type == 'graphic'){
        this.$refs.tugUpload.itemRemove = [];
        this.$refs.tugUpload.filesx = [];
        this.$refs.tugUpload.srcs = [];
      }else if(type == 'sns'){
        this.$refs.tugUpload.itemRemove = [];
        this.$refs.tugUpload.filesx = [];
        this.$refs.tugUpload.srcs = [];
      }else if(type == 'polls1'){
        this.$refs.electionUpload1.itemRemove = [];
        this.$refs.electionUpload1.filesx = [];
        this.$refs.electionUpload1.srcs = [];
      }else if(type == 'polls2'){
        this.$refs.electionUpload2.itemRemove = [];
        this.$refs.electionUpload2.filesx = [];
        this.$refs.electionUpload2.srcs = [];
      }
    },
    setSelected(){
      console.log(this.$refs.tagSelect.$el.querySelector('input'));
      if(this.tagFocusFlg) this.$refs.tagSelect.$el.querySelector('input').focus();
      this.tagFocusFlg = false;
    },
    sendPromotion(){
      console.log(this.promotion);
      const thisIns = this;
      this.$http.post('/api/setting/setPromotion', {data: this.promotion})
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '네이버 프로모션 데이터 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    sendWorldcup(){
      console.log(this.worldcupData)
      let worldcupTmp = JSON.parse(JSON.stringify(this.worldcupData))
      worldcupTmp.schedule = JSON.stringify(worldcupTmp.schedule);
      
      const thisIns = this;
      this.$http.post('/api/setting/setting', {code: 3, data: worldcupTmp})
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '월드컵 데이터 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    olympicSelectDate(event){
      // console.log(event);
      // console.log(this.olympicData.schedule[moment(event).format('YYYY-MM-DD')]);
      this.olympicScheduleText = this.olympicData.schedule[moment(event).format('YYYY-MM-DD')]?.join('\n')
    },
    selectPastData(event){
      if(event != '추가'){
        this.electionPast.image = this.electionData.past[event].image;
        this.electionPast.button = '수정';
        this.$refs.pastUpload.filesx = [];
        this.$refs.pastUpload.srcs = [{
          src: this.electionPast.image,
          orientation: "h",
          type: "image",
          percent: 100,
          error: false,
          remove: null,
        }];
      }else{
        this.electionPast.button = '추가';
        this.electionPast.image = '';
        this.$refs.pastUpload.filesx = [];
        this.$refs.pastUpload.srcs = [];
      }
    },
    selectAreaData(event){
      if(event != '추가'){
        const tmpIdx = this.electionData.tug.findIndex(e => e.area == this.electionTug.area);
        this.electionTug.tags = this.electionData.tug[tmpIdx].data[event].tags;
        this.electionTug.image = this.electionData.tug[tmpIdx].data[event].image;
        this.electionTug.button = '수정';
        this.$refs.tugUpload.filesx = [];
        this.$refs.tugUpload.srcs = [{
          src: this.electionTug.image,
          orientation: "h",
          type: "image",
          percent: 100,
          error: false,
          remove: null,
        }];
      }else{
        this.electionTug.button = '추가';
        this.electionTug.tags = [];
        this.electionTug.image = '';
        this.$refs.tugUpload.filesx = [];
        this.$refs.tugUpload.srcs = [];
      }
    },
    selectTugArea(event){
      this.electionTug.selected = '추가';
      this.electionTug.button = '추가';
      const tmpIdx = this.electionData.tug.findIndex(e => e.area == event);
      if(tmpIdx >= 0){
        this.tugAreaOption = ['추가'];
        for(let i in this.electionData.tug[tmpIdx].data){
          this.tugAreaOption.push(i);
        }
      }else{
        this.tugAreaOption = ['추가'];
      }
      this.selectAreaData('추가')
    },
    selectGraphicData(event){
      if(event != '추가'){
        this.electionGraphic.url = this.electionData.graphic[event].url;
        this.electionGraphic.title = this.electionData.graphic[event].title;
        this.electionGraphic.image = this.electionData.graphic[event].image;
        this.electionGraphic.button = '수정';
        this.$refs.graphicUpload.filesx = [];
        this.$refs.graphicUpload.srcs = [{
          src: this.electionGraphic.image,
          orientation: "h",
          type: "image",
          percent: 100,
          error: false,
          remove: null,
        }];
      }else{
        this.electionGraphic.button = '추가';
        this.electionGraphic.url = '';
        this.electionGraphic.title = '';
        this.electionGraphic.image = '';
        this.$refs.graphicUpload.filesx = [];
        this.$refs.graphicUpload.srcs = [];
      }
    },
    selectSnsData(event){
      if(event != '추가'){
        this.sns.url = this.electionData.sns[event].url;
        this.sns.image = this.electionData.sns[event].image;
        this.sns.button = '수정';
        this.$refs.snsUpload.filesx = [];
        this.$refs.snsUpload.srcs = [{
          src: this.sns.image,
          orientation: "h",
          type: "image",
          percent: 100,
          error: false,
          remove: null,
        }];
      }else{
        this.sns.button = '추가';
        this.sns.url = '';
        this.sns.image = '';
        this.$refs.snsUpload.filesx = [];
        this.$refs.snsUpload.srcs = [];
      }
    },
    setPastImage(evnet){
      console.log(event)
      const cdnPath = "https://cdnimage.dailian.co.kr/ban/";
      this.electionPast.image = cdnPath + evnet.target.response;
      for(let i in this.$refs.pastUpload.srcs){
        this.$refs.pastUpload.srcs[i].orientation = 'h';
      }
      console.log(this.electionPast)
    },
    setTugImage(evnet){
      const cdnPath = "https://cdnimage.dailian.co.kr/ban/";
      this.electionTug.image = cdnPath + evnet.target.response;
      for(let i in this.$refs.tugUpload.srcs){
        this.$refs.tugUpload.srcs[i].orientation = 'h';
      }
      console.log(this.electionTug)
    },
    setGraphicImage(evnet){
      const cdnPath = "https://cdnimage.dailian.co.kr/ban/";
      this.electionGraphic.image = cdnPath + evnet.target.response;
      for(let i in this.$refs.graphicUpload.srcs){
        this.$refs.graphicUpload.srcs[i].orientation = 'h';
      }
      console.log(this.electionGraphic)
    },
    setSnsImage(evnet){
      const cdnPath = "https://cdnimage.dailian.co.kr/ban/";
      this.sns.image = cdnPath + evnet.target.response;
      for(let i in this.$refs.snsUpload.srcs){
        this.$refs.snsUpload.srcs[i].orientation = 'h';
      }
      console.log(this.sns)
    },
    setPollsImage1(evnet){
      const cdnPath = "https://cdnimage.dailian.co.kr/ban/";
      this.election2022Data.image1 = cdnPath + evnet.target.response;
      for(let i in this.$refs.electionUpload1.srcs){
        this.$refs.electionUpload1.srcs[i].orientation = 'h';
      }
    },
    setPollsImage2(evnet){
      const cdnPath = "https://cdnimage.dailian.co.kr/ban/";
      this.election2022Data.image2 = cdnPath + evnet.target.response;
      for(let i in this.$refs.electionUpload2.srcs){
        this.$refs.electionUpload2.srcs[i].orientation = 'h';
      }
    },
    successUpload(evnet){
      const cdnPath = "https://cdnimage.dailian.co.kr/ban/"
      // console.log(evnet.target.response)
      this.bannerTag = `<a href="" target="_blank"><img src="${cdnPath + evnet.target.response}"></a>`
      if(this.bannerLink){ this.bannerTag = this.bannerTag.replace(/href="([^"]*)"/, `href="${this.bannerLink}"`) }
      for(let i in this.$refs.bannerUpload.srcs){
        this.$refs.bannerUpload.srcs[i].orientation = 'h';
      }
      this.$vs.notify({color:'success',title:'Upload Success',text:'배너 이미지 업로드 성공'})
    },
    deleteFile(){
      this.bannerTag = "";
    },
    errorUpload(){
      // console.log(this.$refs.bannerUpload)
      this.$refs.bannerUpload.filesx = [];
      this.$refs.bannerUpload.srcs = [];
    },
    errorSnsUpload(){
      // console.log(this.$refs.bannerUpload)
      this.$refs.snsUpload.filesx = [];
      this.$refs.snsUpload.srcs = [];
    },
    errorGraphicUpload(){
      // console.log(this.$refs.bannerUpload)
      this.$refs.graphicUpload.filesx = [];
      this.$refs.graphicUpload.srcs = [];
    },
    errorTugUpload(){
      // console.log(this.$refs.bannerUpload)
      this.$refs.tugUpload.filesx = [];
      this.$refs.tugUpload.srcs = [];
    },
    errorPastUpload(){
      // console.log(this.$refs.bannerUpload)
      this.$refs.pastUpload.filesx = [];
      this.$refs.pastUpload.srcs = [];
    },
    errorPollsUpload1(){
      // console.log(this.$refs.bannerUpload)
      this.$refs.electionUpload1.filesx = [];
      this.$refs.electionUpload1.srcs = [];
    },
    errorPollsUpload2(){
      // console.log(this.$refs.bannerUpload)
      this.$refs.electionUpload2.filesx = [];
      this.$refs.electionUpload2.srcs = [];
    },
    clearLink(){
      this.searchText = "";
      this.bannerSeries = null;
      this.bannerCategory2 = null;
      this.bannerLink = "";
    },
    clearLink2(){
      this.searchText2 = "";
      this.bannerSeries2 = null;
      this.bannerCategory22 = null;
      this.bannerLink2 = "";
    },
    setBannerLink(type){
      if(type == 'search'){
        this.bannerLink = `/search?shwords=` + encodeURIComponent(this.searchText) + '&sbj=title'
      }
      else if(type == 'searchTag'){
        this.bannerLink = `/search?shwords=` + encodeURIComponent(this.searchText) + '&sbj=hashtag'
      }
      else if(type == 'series'){
        this.bannerLink = `/newsList?series=` + this.bannerSeries.code
      }
      else if(type == 'category2'){
        this.bannerLink = `/newsList?category2=` + this.bannerCategory2.code
      }
      if(this.bannerTag){ this.bannerTag = this.bannerTag.replace(/href="([^"]*)"/, `href="${this.bannerLink}"`) }
      // console.log(this.bannerTag)
    },
    setBannerLink2(type){
      this.bannerTag2 = `<a href="" target="_blank" ${this.bannerData2 ? 'data="' + this.bannerData2 + '"' : ''}}>${this.bannerTitle2}</a>`
      if(type == 'search'){
        this.bannerLink2 = `/search?shwords=` + encodeURIComponent(this.searchText2) + '&sbj=title'
      }
      else if(type == 'searchTag'){
        this.bannerLink2 = `/search?shwords=` + encodeURIComponent(this.searchText2) + '&sbj=hashtag'
      }
      else if(type == 'series'){
        this.bannerLink2 = `/newsList?series=` + this.bannerSeries2.code
      }
      else if(type == 'category2'){
        this.bannerLink2 = `/newsList?category2=` + this.bannerCategory22.code
      }
      if(this.bannerTag2){ this.bannerTag2 = this.bannerTag2.replace(/href="([^"]*)"/, `href="${this.bannerLink2}"`) }
      // console.log(this.bannerTag)
    },
    setSeries(e){
      console.log("event: ", e);
      if(e != null){
        if(e.code != 'new'){
          this.buttonText = "수정";
          this.seriesVar = JSON.parse(JSON.stringify(this.seriesArray.find(data => data.code == e.code)));
          this.on_use = this.seriesUseOption.find(data => data.value == this.seriesVar.on_use);
          this.category1 = this.category1Array.find(data => data.code == e.category1);
          this.preview = 'https://cdnimage.dailian.co.kr/seriesIcon/' + this.seriesVar.banner;
        }else{
          // this.seriesVar.name = '';
          // this.seriesVar.code = '';
          // this.seriesVar.description = '';
          this.seriesVar = JSON.parse(JSON.stringify(this.seriesArray.find(data => data.code == e.code)));
          this.category1 = this.category1Array.find(data => data.code == 0);
          this.on_use = this.seriesUseOption.find(data => data.value == 1);
          this.preview = '';
          this.buttonText = "생성";
        }
        this.seriesEditModalActive=true;
      }
    },
    sendElectionPast(past){
      const thisIns = this;
      if(!past.image){ return alert('이미지가 없습니다.') }
      this.$http.post('/api/setting/electionPast', past)
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '지난선거결과 추가 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    sendElectionTug(tug){
      const thisIns = this;
      if(!tug.image){ return alert('이미지가 없습니다.') }
      else if(tug.tags.length == 0){ return alert('태그가 없습니다.') }
      this.$http.post('/api/setting/electionTug', tug)
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '총선 격전지 추가 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    sendElectionGraphic(graphic){
      const thisIns = this;
      if(!graphic.image){ return alert('이미지가 없습니다.') }
      else if(!graphic.url){ return alert('링크가 없습니다.') }
      else if(!graphic.title){ return alert('제목이 없습니다.') }
      this.$http.post('/api/setting/electionGraphic', graphic)
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '총선 그래픽 데이터 추가 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    sendElectionSns(sns){
      const thisIns = this;
      if(!sns.image){ return alert('이미지가 없습니다.') }
      this.$http.post('/api/setting/electionSns', sns)
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '총선 SNS 데이터 추가 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    sendElection(flg){
      const thisIns = this;
      if(flg){ this.electionData.use = 1; }
      else{ this.electionData.use = 0; }
      this.$http.post('/api/setting/electionKeyword', this.electionData)
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '총선 데이터 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    sendCorona(flg){
      const thisIns = this;
      if(flg){ this.coronaData.use = 1; }
      else{ this.coronaData.use = 0; }
      this.$http.post('/api/setting/corona', this.coronaData)
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '코로나 데이터 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.seriesEditModalActive = false;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.seriesEditModalActive = false;
          thisIns.$vs.loading.close();  
        });
    },
    sendOlympic(){
      this.olympicData.schedule[moment(this.olympicDate).format('YYYY-MM-DD')] = this.olympicScheduleText.split('\n').filter(e => e && e.trim())
      // console.log(this.olympicData);
      this.olympicData.schedule = JSON.stringify(this.olympicData.schedule);
      
      const thisIns = this;
      this.$http.post('/api/setting/setting', {code: 2, data: this.olympicData})
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '올림픽 데이터 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    sendElection2022(){
      let tmpData = this.election2022Text.split('\n');
      let chartData = {};
      for(let i=0; i<tmpData.length; i++){
        const line = tmpData[i].split('\t');
        if(line[0]){
          chartData[line[0]] = line.slice(1);
        }
      }
      this.election2022Data.chartData = JSON.stringify(chartData);
      this.election2022Data.pollNews[0].img = this.election2022Data.image1;
      this.election2022Data.pollNews[1].img = this.election2022Data.image2;
      this.election2022Data.pollNews = JSON.stringify(this.election2022Data.pollNews);
      delete this.election2022Data.image1;
      delete this.election2022Data.image2;
      // console.log(this.election2022Data, chartData, this.election2022Text)
      const thisIns = this;
      this.$http.post('/api/setting/setting', {code: 1, data: this.election2022Data})
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '2022대선 데이터 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    sendSeries(){
      if(this.on_use == null){
        alert('사용 여부를 선택하세요!');
        return;
      }
      this.seriesVar.on_use = this.on_use.value;
      this.seriesVar.category1 = this.category1.code;
      console.log(this.on_use.value, this.seriesVar.on_use);
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/setting/series', this.seriesVar)
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '시리즈 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          // thisIns.$store.commit('SEREIESEDIT', thisIns.seriesArray.filter(series => series.on_use == 1))
          thisIns.created();
          thisIns.seriesEditModalActive = false;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.seriesEditModalActive = false;
          thisIns.$vs.loading.close();  
        });
    },
    sendSidebar(type, data){
      const thisIns = this;
      this.$http.post('/api/setting/sidebarPositions', {type, data})
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '사이드바 순서 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.seriesEditModalActive = false;
          thisIns.$vs.loading.close();  
        });
    },
    makeSidebarTitle(listItem){
      let title = listItem.mcpd_title;
      if(listItem.mcpd_type == 'banner'){
        title = '배너' + listItem.mcpd_dataCode;
      }else if(listItem.mcpd_type == 'ranking'){
        title = '실시간 랭킹';
      }
      if(listItem.mcp_pageType){
        let pageType = '전체';
        if(listItem.mcp_pageType == 'category1'){
          pageType = '오피니언'
        }else if(listItem.mcp_pageType == 'category2'){
          pageType = '정치'
        }else if(listItem.mcp_pageType == 'category3'){
          pageType = '사회'
        }else if(listItem.mcp_pageType == 'category4'){
          pageType = '경제'
        }else if(listItem.mcp_pageType == 'category5'){
          pageType = 'IT/과학'
        }else if(listItem.mcp_pageType == 'category6'){
          pageType = '생활문화'
        }else if(listItem.mcp_pageType == 'category7'){
          pageType = '세계'
        }else if(listItem.mcp_pageType == 'category8'){
          pageType = '연예'
        }else if(listItem.mcp_pageType == 'category9'){
          pageType = '스포츠'
        }
        title += `(표시: ${pageType})`;
      }
      if(listItem.mcp_exceptPageType){
        let pageType = '메인홈';
        if(listItem.mcp_exceptPageType == 'category1'){
          pageType = '오피니언'
        }else if(listItem.mcp_exceptPageType == 'category2'){
          pageType = '정치'
        }else if(listItem.mcp_exceptPageType == 'category3'){
          pageType = '사회'
        }else if(listItem.mcp_exceptPageType == 'category4'){
          pageType = '경제'
        }else if(listItem.mcp_exceptPageType == 'category5'){
          pageType = 'IT/과학'
        }else if(listItem.mcp_exceptPageType == 'category6'){
          pageType = '생활문화'
        }else if(listItem.mcp_exceptPageType == 'category7'){
          pageType = '세계'
        }else if(listItem.mcp_exceptPageType == 'category8'){
          pageType = '연예'
        }else if(listItem.mcp_exceptPageType == 'category9'){
          pageType = '스포츠'
        }
        title += `(비표시: ${pageType})`;
      }
      return title;
    },
    created() {
      // this.seriesArray = this.$store.state.series;
      this.seriesArray = [{code: "new", name: "시리즈 추가", on_use: ''}];
      this.seriesVar.name = '';
      this.seriesVar.code = '';
      this.seriesVar.description = '';
      this.seriesVar.on_use = this.seriesUseOption.find(data => data.value == 1);
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/getVarList')
        .then(function (res) {
          console.log("get data: ", res.data);
          thisIns.category1Array = [{code: 0, name: '전체'}].concat(res.data[0]);
          thisIns.seriesArray = thisIns.seriesArray.concat(res.data[4]);
          thisIns.coronaData = res.data[5];
          thisIns.coronaData.date = new Date(moment(thisIns.coronaData.date))
          thisIns.electionData = res.data[6];
          if(!thisIns.electionData.tug){
            thisIns.electionData.tug = [];
          }else{
            thisIns.tugAreaOption = ['추가'];
            for(let i in thisIns.electionData.tug[0].data){
              thisIns.tugAreaOption.push(i);
            }
          }
          if(!thisIns.electionData.past){
            thisIns.electionData.past = [];
          }else{
            thisIns.pastOption = ['추가'];
            for(let i in thisIns.electionData.past){
              thisIns.pastOption.push(i);
            }
          }


          // console.log(thisIns.electionData, thisIns.electionData.state)
          // console.log(thisIns.seriesArray, thisIns.seriesArray.filter(series => series.on_use == 1));
          thisIns.$store.dispatch('setSeriesState', {'seriesArray': thisIns.seriesArray.filter(series => series.on_use == 1).sort((a,b) => {
                            if(a.name > b.name){
                                return 1
                            }else{
                                return -1
                            }})});
          // thisIns.$store.commit('SEREIESEDIT', thisIns.seriesArray.filter(series => series.on_use == 1))

          if(res.data[7]){
            thisIns.sidebarMain = res.data[7].filter(e => e.smp_sidebarType == 'sidebarMain');
            thisIns.sidebarArticle = res.data[7].filter(e => e.smp_sidebarType == 'sidebarArticle');
          }
          if(res.data[8]){
            thisIns.sidebarRenew = res.data[8];
          }
          let worldcupTmp = res.data[9].filter(e => e.code == 3);
          // console.log(worldcupTmp)
          worldcupTmp.forEach(row => {
            if(row.name == "state"){
              thisIns.worldcupData.state = row.value;
            }else if(row.name == "schedule"){
              try {
                thisIns.worldcupData.schedule = JSON.parse(row.value);
                if(thisIns.worldcupData.schedule && thisIns.worldcupData.schedule[0]){
                  thisIns.worldcupSelected = thisIns.worldcupData.schedule[0];
                }
              } catch (error) {
                thisIns.$vs.notify({
                  title:'Error',
                  text: "월드컵 데이터 파싱 실패",
                  color:'danger',
                  iconPack: 'feather',
                  icon:'icon-alert-circle'})
              }
            }
          })
          // thisIns.olympicData = res.data[9];
          
          // // thisIns.olympicData.state = thisIns.olympicData.state.toString();
          // thisIns.olympicSelectDate(moment());


          // thisIns.election2022Data = res.data[10];
          // thisIns.election2022Text = "";
          // if(typeof thisIns.election2022Data.chartData == 'object'){
          //   for(const key of Object.keys(thisIns.election2022Data.chartData)){
          //     thisIns.election2022Text += key + '\t' + thisIns.election2022Data.chartData[key].join('\t') + '\n';
          //   }
          // }
          // thisIns.election2022Data.image1 = thisIns.election2022Data.pollNews[0].img;
          // thisIns.election2022Data.image2 = thisIns.election2022Data.pollNews[1].img;

          return thisIns.$http.post('/api/setting/getPromotion')
        }).then(function (res) {
          thisIns.promotion = res.data;
          for(const item of thisIns.promotion){
            item.qr = item.qr ? true : false;
          }
          console.log("pro: ", thisIns.promotion)
          thisIns.$vs.loading.close(); 
        }).catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },

    addFile(e){
      // console.log(e.target.files, e.target.files[0]);
      if(e.target.files.length ){
        this.files[0] = e.target.files[0];
        this.submitFiles(e.target.files);
      }
    },
    /*
      Determines if the drag and drop functionality is in the
      window
    */
    determineDragAndDropCapable(){
      /*
        Create a test element to see if certain events
        are present that let us do drag and drop.
      */
      var div = document.createElement('div');

      /*
        Check to see if the `draggable` event is in the element
        or the `ondragstart` and `ondrop` events are in the element. If
        they are, then we have what we need for dragging and dropping files.

        We also check to see if the window has `FormData` and `FileReader` objects
        present so we can do our AJAX uploading
      */
      return ( ( 'draggable' in div )
              || ( 'ondragstart' in div && 'ondrop' in div ) )
              && 'FormData' in window
              && 'FileReader' in window;
    },

    /*
      Submits the files to the server
    */
    submitFiles(files){
      /*
        Initialize the form data
      */
      let formData = new FormData();
      let thisIns = this;

      /*
        Iteate over any file sent over appending the files
        to the form data.
      */
      for( var i = 0; i < files.length; i++ ){
        let file = files[i];
        formData.append('file', file);
      }
      formData.append('type', 11);
      formData.append('partName', thisIns.seriesVar.code);
      /*
        Make the request to the POST /file-drag-drop-instant URL
      */
      let targetUrl = '/api/fileUpload';
      this.$http.post( targetUrl,
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      ).then(function(response){
        console.log('SUCCESS!!', response);
        if(response.data.length){
            thisIns.seriesVar.banner = response.data[0];
            thisIns.preview = "/api/photo/viewImages?filename=" + response.data[0];
        }
        // thisIns.getImagePreviews();
      })
      .catch(function(response){
        console.log('FAILURE!!', response);
      });
    },

    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    this.created();
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();

    /*
      If drag and drop capable, then we continue to bind events to our elements.
    */
    if( this.dragAndDropCapable ){
      /*
        Listen to all of the drag events and bind an event listener to each
        for the fileform.
      */
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
        /*
          For each event add an event listener that prevents the default action
          (opening the file in the browser) and stop the propagation of the event (so
          no other elements open the file in the browser)
        */
        this.$refs.fileform.addEventListener(evt, function(e){
          e.preventDefault();
          e.stopPropagation();
        }.bind(this), false);
      }.bind(this));

      /*
        Add an event listener for drop to the form
      */
      this.$refs.fileform.addEventListener('drop', function(e){
        /*
          Capture the files from the drop event and add them to our local files
          array.
        */
        this.files[0] = e.dataTransfer.files[0];
        console.log(e.dataTransfer.files);
        this.submitFiles(e.dataTransfer.files);
      }.bind(this));
    }
  }
}
</script>

<style lang="scss">
.popup-editSeries{
  .seriesBanner{  
    form{
      display: inline-block;
      height: 150px;
      width: 150px;
      background: #ccc;
      text-align: center;
      border-radius: 4px;
      vertical-align: bottom;
    }
    .drop-files{
      p{
        padding-top: 50px;
        height: 100%;
      }
    }
    input[type="file"] { /* 파일 필드 숨기기 */ 
      position: absolute; 
      width: 1px; 
      height: 1px; 
      padding: 0; 
      margin: -1px; 
      overflow: hidden; 
      clip:rect(0,0,0,0); 
      border: 0; 
    }
    progress{
      width: 150px;
      margin: auto;
      display: block;
      margin-top: -20px;
    }
  }
}
</style>